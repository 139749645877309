import React, { Component } from 'react';
import iffun from '@bit/rivigo.ui.utils.iffun';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import constants from '../constants/jsonDataConstants';
import { FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon, MailIcon } from '../custom-icons';
import SecHeading from '../components/SectionHeading/SectionHeading';
import { transformDate, oldestDate, latestDate, currDatePrevYear } from '../utils/csvDataManipulation';
import { device } from '../constants/device';

const TableWrapper = styled.div`
  overflow-x: auto;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 30px;
  &::-webkit-scrollbar {
    height: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: #606062;
    border: 1px solid rgba(96, 96, 98, 0.26);
    border-top: 0;
    border-bottom: 0;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
    box-shadow: inset 0px 0px 8px 0px #606062;
    border-radius: 0px;
  }
  @media ${device.tablet} {
    max-width: calc(100vw - 30px);
  }
`;
const Table = styled.table`
  background: #ffffff;
  border-radius: 4px;
  color: #2a2a2a;
  width: 100%;
`;

const TableRow = styled.tr`
  font-size: 14px;
  height: 56px;
  &:nth-of-type(n + 3) {
    td {
      &:first-of-type {
        padding-left: 36px;
      }
    }
  }
  td {
    min-width: 80px;
    text-align: center;
    &:first-of-type {
      text-align: left;
      padding-left: 24px;
      min-width: 147px;
    }
    &.green {
      color: #19b985;
    }
    &.red {
      color: #e3383a;
    }
  }
  &.active {
    background: #f1f1f1;
    font-size: 16px;
    font-weight: 700;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.3);
  }
`;
const TableHead = styled(TableRow)`
  font-size: 12px;
  font-weight: 700;
  border-bottom: 1px solid #d8d8d8;
  em {
    color: #969696;
    display: block;
    font-style: normal;
  }
`;

function getLastUpdatedTime(epochValue) {
  let timeDiffInMinutes = (Date.now() / 1000 - epochValue) / 60;
  return `  (refreshed ${Math.floor(timeDiffInMinutes)} mins ago)`;
}

class LiveIndexTable extends Component {
  render() {
    let { data: liveIndexData, truckSubTypeValue, truckTypeValue, liveRatesDataFromApi } = this.props;
    let getOldestDate = oldestDate();
    let getLatestDate = latestDate();
    let oldDate = transformDate(getOldestDate);
    let newestDate = transformDate(getLatestDate);
    let currDate = currDatePrevYear();
    return (
      <>
        <SecHeading>
          <p>
            Major Lanes
            {/* <span className='refreshed'>
              {iffun(
                liveRatesDataFromApi.length > 0,
                () => getLastUpdatedTime(liveRatesDataFromApi[0].lastUpdatedAtEpoch),
                null
              )}
            </span> */}
          </p>
          <cite>
            {/* <p className='socialIcons'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.facebook.com/sharer/sharer.php?u=https://nationalfreightindex.co.in'
                className='social'>
                <FacebookIcon />
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.linkedin.com/shareArticle/?url=https://nationalfreightindex.co.in'
                className='social'>
                <LinkedinIcon />
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://twitter.com/share?url=https://nationalfreightindex.co.in'
                className='social'>
                <TwitterIcon />
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://web.whatsapp.com/send?text=https://nationalfreightindex.co.in'
                className='social'>
                <WhatsappIcon />
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='mailto:?body=https://nationalfreightindex.co.in'
                className='social'>
                <MailIcon />
              </a>
            </p> */}
          </cite>
        </SecHeading>
        <TableWrapper>
          <Table cellPadding='0' cellSpacing='0'>
            <tbody>
              <TableHead>
                <td>&nbsp;</td>
                <td>
                  Live rate <em>(₹ per ton-km)</em>
                </td>
                <td>
                  Live index <em>{`(pegged to ${oldDate})`}</em>
                </td>
                <td>
                  Change <em>{`vs ${newestDate}`}</em>
                </td>
                <td>
                  Change <em>{`vs ${transformDate(currDate)}`}</em>
                </td>
              </TableHead>
              {liveIndexData.map((liveIndexRow) => {
                let rowHeading = liveIndexRow.rowHeading;
                if (rowHeading === constants.tyre10) {
                  rowHeading = constants.formattedTyre10;
                } else if (rowHeading === constants.tyre12) {
                  rowHeading = constants.formattedTyre12;
                } else if (rowHeading === constants.tyre14) {
                  rowHeading = constants.formattedTyre14;
                } else if (rowHeading === constants.tyre19ft) {
                  rowHeading = constants.formattedTyre19ft;
                }
                return (
                  <TableRow
                    className={iffun(
                      (truckSubTypeValue !== constants.ALL && liveIndexRow.rowHeading === truckSubTypeValue) ||
                        (truckSubTypeValue === constants.ALL && liveIndexRow.rowHeading === truckTypeValue),
                      'active',
                      ''
                    )}
                    key={liveIndexRow.rowHeading}>
                    <td>{iffun(rowHeading === constants.ALL, 'All trucks', rowHeading)}</td>
                    <td>{liveIndexRow['Live Rate']}</td>
                    <td>{liveIndexRow['Live Index']}</td>
                    <td className={iffun(liveIndexRow.ChangeFromFirst > 0, 'green', 'red')}>{`${
                      liveIndexRow.ChangeFromFirst
                    }%`}</td>
                    <td className={iffun(liveIndexRow.ChangeFromLast > 0, 'green', 'red')}>{`${
                      liveIndexRow.ChangeFromLast
                    }%`}</td>
                  </TableRow>
                );
              })}
            </tbody>
          </Table>
        </TableWrapper>
      </>
    );
  }
}

LiveIndexTable.propTypes = {
  data: PropTypes.array,
  truckTypeValue: PropTypes.string,
  truckSubTypeValue: PropTypes.string,
  liveRatesDataFromApi: PropTypes.array
};

LiveIndexTable.testProps = [
  {
    data: [
      {
        rowHeading: 'Open',
        'Live Rate': 2.5,
        'Live Index': 100,
        ChangeFromFirst: '20%',
        ChangeFromLast: '10%'
      },
      {
        rowHeading: '14tyre',
        'Live Rate': 2.5,
        'Live Index': 100,
        ChangeFromFirst: '20%',
        ChangeFromLast: '10%'
      },
      {
        rowHeading: '10tyre',
        'Live Rate': 2.5,
        'Live Index': 100,
        ChangeFromFirst: '20%',
        ChangeFromLast: '10%'
      }
    ]
  }
];

export default LiveIndexTable;
