import styled from 'styled-components';
// import { device } from '../constants/device';

const DirArrow = styled.i`
  position: absolute;
  top: calc(50% - 7px);
  color: #969696;
  font-size: 18px;
  cursor: pointer;
  &.pre {
    left: -10px;
    transform: rotate(180deg);
  }
  &.next {
    right: -10px;
  }
`;

export { DirArrow };
