import React, { Component } from 'react';
import { ComponentPlayGround } from '../other/ComponentPlayGround';

class Components extends Component {
  render() {
    return <ComponentPlayGround />;
  }
}

export default Components;
