import React from 'react';
import SplitPane from 'react-split-pane';
import iffun from '@bit/rivigo.ui.utils.iffun';
import styled from 'styled-components';
import loopobject from 'loopobject';
import allComponents from './allComponents';
import ComponentLoader from './ComponentLoader';

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  border: 5px solid #c7a3a3;
  .Resizer {
    background: #000;
    background: #c7a3a3;
    z-index: 1;
    &.horizontal {
      height: 5px;
      cursor: row-resize;
    }
    &.vertical {
      width: 5px;
      cursor: col-resize;
    }
    &.disabled {
      cursor: not-allowed;
      :hover {
        border-color: transparent;
      }
    }
  }
`;
const Heading = styled.div`
  font-weight: bold;
  font-size: 28px;
  width: 100%;
  //border: 2px solid blue;
  height: 100%;
  padding: 10px;
`;

const SideBar = styled.div`
  //border: 2px solid #0e3e0e;
  padding: 20px;
  overflow: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Outer = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  //border: 3px solid #e01515;
  overflow: auto;
  flex-grow: 1;
`;

const ComponentPlayGround = () => {
  if (typeof window !== 'undefined') {
    let componentName = window.location.search.split('?c=')[1];
    let SelectedComponent = allComponents[componentName];
    return (
      <Container>
        <SplitPane class='custom-css-resize' split='horizontal'>
          <Heading>Component PlayGround</Heading>
          <SplitPane split='vertical' minSize={150}>
            <SideBar>
              {loopobject(allComponents, (v, i) => {
                return (
                  <a href={`/components?c=${i}`} key={i}>
                    {i}
                  </a>
                );
              })}
            </SideBar>

            <Outer>
              {iffun(SelectedComponent !== undefined, () => (
                <ComponentLoader Comp={SelectedComponent} />
              ))}
            </Outer>
          </SplitPane>
        </SplitPane>
      </Container>
    );
  } else {
    return <div>Server Renderd code</div>;
  }
};

export { ComponentPlayGround };
