import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  border: 1px solid black;
  padding: 6px;
  margin: 0px;
  width: 533px;
  height: 152px;
`;
const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

export default class PropEditor extends Component {
  static propTypes = {
    p: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
  };

  render() {
    return (
      <Container>
        <Textarea
          onChange={(evt) => {
            var newVal = evt.target.value;
            try {
              //Step1 = validate
              var newProps = JSON.parse(newVal);
            } catch (error) {
              return;
            }

            this.props.onChange(newProps);
          }}
          name=''
          id=''
          value={JSON.stringify(this.props.p, null, '\t')}
        />
      </Container>
    );
  }
}
