import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import styled from 'styled-components';
// import { ChevronIcon } from '../custom-icons';
import { DirArrow } from '../components-pure/DirectionArrows';
import { getFormattedPrice } from '../utils/csvDataManipulation';

const Container = styled.div`
  width: 100%;
  position: relative;
`;

let options = {
  colors: ['#979797'],
  lineWidth: 4,
  pointSize: 10,
  height: '280px',
  backgroundColor: '#ededed',
  chartArea: {
    width: '78%',
    height: '70%'
  },
  legend: {
    position: 'none'
  },
  vAxis: {
    textStyle: {
      color: '#2a2a2a'
    },
    gridlines: {
      color: '#c0c0c0'
    },
    baselines: {
      color: '#2a2a2a'
    },
    minorGridlines: {
      color: 'transparent'
    }
  },
  hAxis: {
    textStyle: {
      color: '#2a2a2a',
      fontSize: 12
    },
    baselines: {
      color: 'transparent'
    },
    gridlines: {
      color: 'transparent'
    },
    minorGridlines: {
      color: 'transparent'
    },
    format: 'MMM'
  },
  annotations: {
    boxStyle: {
      stroke: '#c0c0c0',
      strokeWidth: 1,
      rx: 2,
      gradient: {
        color1: '#ffffff',
        color2: '#ffffff',
        x1: '0%',
        y1: '0%',
        x2: '100%',
        y2: '100%'
      }
    },
    textStyle: {
      color: '#2a2a2a',
      fontSize: 14,
      auraColor: '#ffffff'
    },
    highContrast: false,
    stem: {
      color: 'transparent'
    },
    alwaysOutside: true
  }
};

function getTooltipHTML(price, date) {
  const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let formattedPrice = getFormattedPrice(price);
  let formattedDate = Months[new Date(date).getMonth()];
  formattedDate += ` ${new Date(date).getDate()}, ${new Date(date).getFullYear()} `;
  return `${formattedDate}
  Price: ${formattedPrice} `;
}

function transformToGraphData(laneHistory) {
  let finalData = [];
  finalData.push([
    'Day',
    'Price',
    { type: 'string', role: 'tooltip', p: { html: true } },
    { type: 'string', role: 'style' },
    { type: 'string', role: 'annotation' }
  ]);
  if (Array.isArray(laneHistory) === true) {
    laneHistory.forEach((history, index) => {
      let size = 0,
        annotation = '';
      if (index % 30 === 0 && index !== 0) {
        size = 5;
      }
      if (index === laneHistory.length - 1) {
        size = 5;
      }
      finalData.push([
        new Date(history.EpochTimeMillis),
        history.MarketPrice,
        getTooltipHTML(history.MarketPrice, history.EpochTimeMillis),
        `point { stroke-width: 4; shape-type: circle; fill-color: #ffffff; stroke-color: #979797 ;size:${size}}`,
        annotation
      ]);
    });
  }
  return finalData;
}

export default class TopLaneGraph extends Component {
  state = {
    arrowStep: 0
  };

  handlePreviousArrow = () => {
    if (this.state.arrowStep !== 0) {
      this.setState({ arrowStep: this.state.arrowStep - 5 });
    }
  };

  handleNextArrow = () => {
    if (this.state.arrowStep !== 10) {
      this.setState({ arrowStep: this.state.arrowStep + 5 });
    }
  };

  render() {
    let graphData = transformToGraphData(this.props.laneHistory);
    let modifiedOptions = Object.assign({}, options);
    modifiedOptions.hAxis.ticks = [];
    this.props.laneHistory.forEach((history, index) => {
      if (index % 30 === 0 && index !== 0) {
        modifiedOptions.hAxis.ticks.push({ v: new Date(history.EpochTimeMillis), f: `${history.Date}` });
      }
    });
    // let splicedData = [graphData[0], ...graphData.slice(this.state.arrowStep + 1, this.state.arrowStep + 6)];
    return (
      <Container>
        <DirArrow className='pre'>{/* <ChevronIcon onClick={this.handlePreviousArrow} /> */}</DirArrow>
        <Chart data={graphData} options={options} chartType='LineChart' />
        <DirArrow className='next'>{/* <ChevronIcon onClick={this.handleNextArrow} /> */}</DirArrow>
      </Container>
    );
  }
}

TopLaneGraph.propTypes = {
  laneHistory: PropTypes.array
};
