import React, { Component } from 'react';
import styled from 'styled-components';
import iffun from '@bit/rivigo.ui.utils.iffun';
import PropTypes from 'prop-types';
import Lang from '../ln/Lang';
import { LockIcon, CircleIcon } from '../custom-icons';
import liveFeedServices from '../services/liveFeedServices';
import LiveRateItem from '../components-pure/LiveRateItem';
import LiveFeedPopup from './LiveFeedPopup';
import { device } from '../constants/device';
import userServices from '../services/userServices';

const TickerWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  color: #2a2a2a;
  position: sticky;
  top: 60px;
  z-index: 6;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  h3 {
    font-size: 16px;
    background: #606062;
    padding: 16px 10px;
    color: #ffffff;
    align-content: center;
    text-transform: uppercase;
    line-height: 1;
    min-width: 140px;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 2;
    &.last {
      right: 0;
    }
    &.first {
      left: 0;
    }
  }
  @media ${device.mobileL} {
    .first {
      font-size: 14px;
      min-width: 100px;
      padding: 9px 10px;
    }
    .last {
      display: none;
    }
  }
`;
const TickerCtr = styled.div`
  position: relative;
  overflow: hidden;
`;

const Ticker = styled.div`
  display: flex;
  padding-left: 140px;
  &:hover {
    .tickerList {
      animation-play-state: paused;
    }
  }
  @media ${device.mobileL} {
    padding-left: 100px;
  }
`;

const LiveRateWrapper = styled.div`
  display: flex;
  animation: ticker 1600s infinite linear;
`;

class LiveRateTicker extends Component {
  state = {
    liveFeedConst: [],
    liveFeed: [],
    showLiveFeedPopup: false,
    fromLocation: null,
    toLocation: null,
    offset: 0,
    loading: false
  };

  async componentWillMount() {
    let apiResult = await liveFeedServices.getLiveFeed();
    if (apiResult && Array.isArray(apiResult.rateExchangeDtos)) {
      let liveFeedConst100Times = [];
      for (let i = 0; i < 20; i++) {
        liveFeedConst100Times = [...liveFeedConst100Times, ...apiResult.rateExchangeDtos];
      }
      this.setState({
        liveFeed: apiResult.rateExchangeDtos,
        liveFeedConst: liveFeedConst100Times
      });
    }
  }

  getMoreLiveFeed = async () => {
    if (this.state.liveFeed.length < 10) {
      this.setState({ loading: true });
    }
    let apiResult = await liveFeedServices.getLiveFeed(
      this.state.offset,
      this.state.fromLocation,
      this.state.toLocation
    );
    this.setState({ offset: apiResult.offset, loading: false }, async () => {
      if (this.state.liveFeed.length > 10) {
        let newFeed = this.state.liveFeed.concat(apiResult.rateExchangeDtos);
        this.setState({ liveFeed: newFeed });
        return;
      } else {
        this.setState({ loading: true });
        let moreResult = await liveFeedServices.getLiveFeed(
          this.state.offset,
          this.state.fromLocation,
          this.state.toLocation
        );
        let newLiveFeed = [];
        try {
          newLiveFeed = apiResult.rateExchangeDtos.concat(moreResult.rateExchangeDtos);
        } catch (err) {
          console.error(err);
        }
        this.setState({ liveFeed: newLiveFeed, offset: moreResult.offset, loading: false });
      }
    });
  };

  handleFiltersApply = (fromLocation, toLocation) => {
    this.setState({ fromLocation: fromLocation, toLocation: toLocation, offset: 0, liveFeed: [] }, () => {
      this.getMoreLiveFeed();
    });
  };

  liveRateTickerClick = () => {
    userServices.sendAnalyticsEvents({
      category: 'VRE',
      action: `Clicks View ALL`,
      label: ''
    });
    if (this.props.isUserLoggedIn === false) {
      document.getElementById('loginButton').click();
      return;
    }
    this.setState({ showLiveFeedPopup: true });
  };

  handleCloseBtnClick = () => {
    this.setState({ showLiveFeedPopup: false, fromLocation: null, toLocation: null, offset: 0, liveFeed: [] });
  };

  render() {
    const { liveFeed, showLiveFeedPopup, liveFeedConst } = this.state;
    const { isUserLoggedIn, truckMappings } = this.props;
    return (
      <>
        <TickerWrapper onClick={this.liveRateTickerClick}>
          <TickerCtr>
            <h3 className='first'>
              <Lang>pricingIndex.LIVE_RATES</Lang>
            </h3>
            <Ticker>
              <LiveRateWrapper className='tickerList'>
                {truckMappings &&
                  liveFeedConst.map((feed, index) => {
                    return (
                      <LiveRateItem key={index}>
                        <p>
                          <span>
                            {feed.startAddress.formattedAddress} - {feed.endAddress.formattedAddress}
                          </span>
                          <em>{truckMappings.typeBindings[feed.vehicleTypeId]}</em>
                          <CircleIcon color='#969696' />
                          <em>{truckMappings.lengthBindings[feed.vehicleLengthId]}</em>
                        </p>
                        <p>
                          {iffun(
                            isUserLoggedIn === true,
                            <span>{feed.displayVyomRate}</span>,
                            <>
                              ₹<LockIcon />
                            </>
                          )}
                        </p>
                      </LiveRateItem>
                    );
                  })}
              </LiveRateWrapper>
            </Ticker>
            <h3 className='last'>VIEW ALL</h3>
          </TickerCtr>
        </TickerWrapper>
        {iffun(
          showLiveFeedPopup === true,
          () => (
            <LiveFeedPopup
              liveFeed={liveFeed}
              onCloseBtnClick={this.handleCloseBtnClick}
              getMoreLiveFeed={this.getMoreLiveFeed}
              handleFiltersApply={this.handleFiltersApply}
              truckMappings={truckMappings}
              loading={this.state.loading}
            />
          ),
          null
        )}
      </>
    );
  }
}

LiveRateTicker.propTypes = {
  isUserLoggedIn: PropTypes.bool,
  truckMappings: PropTypes.object
};

export default LiveRateTicker;
