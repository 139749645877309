import Spinner from '@bit/rivigo.ui.spinner';
import LiveRateTicker from '../components/LiveRateTicker';
import AutoSuggestWrapper from '../components/common/AutoSuggestWrapper';
import AppDownload from '../components/AppDownload';
import Footer from '../components/footer';
import KeyLanes from '../components/KeyLanes';
import GraphTest from '../components/GraphTest';
import LiveIndexTable from '../components/LiveIndexTable';
import TopLanes from '../components/TopLanes';
import PulseCheck from '../components/PulseCheck';
import LiveFeedPopup from '../components/LiveFeedPopup';

const allComponents = {
  Spinner,
  LiveRateTicker,
  AutoSuggestWrapper,
  AppDownload,
  Footer,
  KeyLanes,
  GraphTest,
  LiveIndexTable,
  TopLanes,
  PulseCheck,
  LiveFeedPopup
};
export default allComponents;
