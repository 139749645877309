import React from 'react';
import langEngine from 'lang-engine';
import PropTypes from 'prop-types';
import allLangData from './allLangData';
import sendErrorLogToSentry from '../utils/sendSentryLog';

const Lang = (props) => {
  if (allLangData[props.children.trim()] === undefined) {
    sendErrorLogToSentry(`key not found - ${props.children.trim()}`);
  }
  return <>{langEngine.resolve(props.children.trim(), props.data)}</>;
};

Lang.propTypes = {
  children: PropTypes.string.isRequired,
  data: PropTypes.object
};

export default Lang;
