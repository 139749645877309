import React, { Component } from 'react';
import styled from 'styled-components';
import SecHeading from '../components/SectionHeading/SectionHeading';

const PulseCheckCtr = styled.div`
  color: #2a2a2a;
  font-size: 14px;
  background: #ffffff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
  .pulseCheckCta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    font-size: 12px;
    span {
      width: 45%;
      cursor: pointer;
      color: #2a2a2a;
      border: 1px solid #2a2a2a;
      border-radius: 4px;
      padding: 10px;
      text-align: center;
      &:hover {
        background: #f7f7f7;
      }
    }
    &.rslt {
      span {
        border: none;
        border-radius: 0;
        color: #ffffff;
        width: auto;
        flex-grow: 1;
        cursor: default;
        &:hover {
          background: inherit;
        }
      }
      span.yes {
        border: 1px solid #19b985;
        background: #19b985;
      }
      span.no {
        background: #e3383a;
        border: 1px solid #e3383a;
      }
    }
  }
`;

class PulseCheck extends Component {
  render() {
    return (
      <>
        <SecHeading>Pulse check</SecHeading>{' '}
        <PulseCheckCtr>
          <p>Will you be adding more trucks to your fleet after the recent repo rate revision?</p>
          <p className='pulseCheckCta'>
            <span>Yes</span>
            <span>No</span>
          </p>
          <p className='pulseCheckCta rslt'>
            <span className='yes' style={{ width: '60.50%' }}>
              Yes (60.50%)
            </span>
            <span className='no' style={{ width: '39.50%' }}>
              No (39.50%)
            </span>
          </p>
        </PulseCheckCtr>
      </>
    );
  }
}

export default PulseCheck;
