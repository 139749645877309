import React, { Component } from 'react';
import PropTypes from 'prop-types';
import iffun from '@bit/rivigo.ui.utils.iffun';
import styled from 'styled-components';
import SecHeading from '../components/SectionHeading/SectionHeading';
import constants from '../constants/jsonDataConstants';
import { CircleIcon } from '../custom-icons';
import TopLanesPopup from './TopLanesPopup';
import userServices from '../services/userServices';
import liveRateServices from '../services/liveRatesServices';
import { getFormattedPrice } from '../utils/csvDataManipulation';

const TopLanesCtr = styled.div`
  margin-bottom: 30px;
  background: #ffffff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
`;
const TopLaneRates = styled.div`
  &&& {
    padding: 12px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    background: #f1f1f1;
    font-size: 14px;
    color: #2a2a2a;
    cursor: pointer;
    &:last-of-type {
      margin-bottom: 0;
    }
    p {
      font-weight: 700;
      svg {
        font-size: 6px;
        vertical-align: middle;
        color: #969696;
        margin: 0 10px;
      }
      i {
        margin: 0 0 0 10px;
        svg {
          font-size: 14px;
          color: #e3383a;
          margin: 0;
        }
      }
    }
  }
`;

const OriginDest = styled.span`
  display: block;
  font-size: 14px;
  max-width: 175px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #2a2a2a;
`;

const TrkTyp = styled.span`
  font-size: 12px;
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  em {
    position: relative;
    font-style: normal;
    color: #969696;
    font-weight: 400;
    max-width: 80px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

class TopLanes extends Component {
  state = {
    showTopLanePopup: false,
    selectedLane: null,
    laneHistory: []
  };

  handleTopLaneClick = async (laneData) => {
    let laneHistoryObj = {
      laneId: laneData.laneId,
      vehicleLengthId: laneData.vehicleLengthId,
      vehicleTypeId: laneData.vehicleTypeId,
      vehicleWeightId: laneData.vehicleWeightId
    };
    let apiResult = await liveRateServices.getLanesHistory(laneHistoryObj);
    this.setState({ showTopLanePopup: true, selectedLane: laneData, laneHistory: apiResult.pricingHistory || [] });
    userServices.sendAnalyticsEvents({
      category: 'TopLanes',
      action: `Clicks Lane: ${JSON.stringify(laneData)}`,
      label: this.props.getAnalyticsEventValue()
    });
  };

  handleCloseBtnClick = () => {
    this.setState({ showTopLanePopup: false, selectedLane: null });
  };

  render() {
    let { data: topLanesData, truckMappings } = this.props;
    const { showTopLanePopup, selectedLane, laneHistory } = this.state;
    return (
      <>
        <SecHeading>Top lanes</SecHeading>
        <TopLanesCtr>
          {truckMappings &&
            Array.isArray(topLanesData) &&
            topLanesData.map((laneData, index) => {
              return (
                <TopLaneRates key={index}>
                  <p>
                    <OriginDest>
                      {laneData.sourceAddress.split(',')[0]} - {laneData.destinationAddress.split(',')[0]}
                    </OriginDest>
                    <TrkTyp>
                      <em>{truckMappings.typeBindings[laneData.vehicleTypeId]}</em> <CircleIcon />
                      <em>{truckMappings.lengthBindings[laneData.vehicleLengthId]}</em>
                      <CircleIcon />
                      <em>{truckMappings.weightBindings[laneData.vehicleWeightId]}</em>
                    </TrkTyp>
                  </p>
                  <p>
                    <span>{getFormattedPrice(laneData.marketPrice)}</span>
                    {/* <i>
                      <ChevronIcon />
                    </i> */}
                  </p>
                </TopLaneRates>
              );
            })}
        </TopLanesCtr>
        {iffun(
          showTopLanePopup === true,
          <TopLanesPopup
            onCloseBtnClick={this.handleCloseBtnClick}
            selectedLane={selectedLane}
            truckMappings={truckMappings}
            laneHistory={laneHistory}
          />,
          null
        )}
      </>
    );
  }
}

TopLanes.propTypes = {
  data: PropTypes.array,
  truckMappings: PropTypes.object,
  getAnalyticsEventValue: PropTypes.func
};

TopLanes.testProps = [
  {
    data: [
      {
        [constants.ODVT]: '2321312_open_10tyre',
        [constants.WEIGHT_SCORE]: '0.00034234'
      },
      {
        [constants.ODVT]: '132222_open_14tyre',
        [constants.WEIGHT_SCORE]: '0.000142311'
      }
    ]
  }
];

export default TopLanes;
