import React, { Component } from 'react';
import PropTypes from 'prop-types';
import iffun from '@bit/rivigo.ui.utils.iffun';
import styled from 'styled-components';
import { Chart } from 'react-google-charts';
import { getAllDates, transformDate } from '../utils/csvDataManipulation';
import { ChevronIcon } from '../custom-icons';
import { device } from '../constants/device';
import constants from '../constants/jsonDataConstants';
import { DirArrow } from '../components-pure/DirectionArrows';

const allDates = getAllDates();

const GraphHead = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  color: #2a2a2a;
  font-size: 12px;
  margin-bottom: 15px;
  .graphCntnt {
    padding-right: 10px;
    @media ${device.mobileL} {
      span {
        display: block;
        white-space: nowrap;
      }
    }
  }
`;

const GraphLabels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 7px;
`;

const CheckboxWrapper = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  input {
    visibility: hidden;
  }
  .checkmark {
    position: relative;
    height: 18px;
    width: 18px;
    border: 2px solid #969696;
    border-radius: 2px;
    background: #ffffff;
    display: inline-block;
  }

  input:checked + .checkmark {
    background: #2a2a2a;
    border: 2px solid #2a2a2a;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .legend {
    position: relative;
    display: inline-block;
    margin: 0 10px;
    height: 3px;
    width: 30px;
    background: #f5a623;
    &:after {
      content: '';
      position: absolute;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background: inherit;
      top: -6px;
      left: 8px;
    }
  }
  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }
`;

let options = {
  height: '280px',
  chartArea: {
    width: '100%',
    height: '100%'
  },
  legend: {
    position: 'none'
  },
  annotations: {
    boxStyle: {
      stroke: '#c0c0c0',
      strokeWidth: 1,
      rx: 2,
      gradient: {
        color1: '#ffffff',
        color2: '#ffffff',
        x1: '0%',
        y1: '0%',
        x2: '100%',
        y2: '100%'
      }
    },
    textStyle: {
      color: '#2a2a2a',
      fontSize: 11
    },
    stem: {
      color: 'transparent'
    },
    alwaysOutside: true
  },
  bar: {
    groupWidth: '25'
  },
  tooltip: {
    isHtml: true
  },
  seriesType: 'bars',
  backgroundColor: 'none',
  fontSize: '9',
  colors: ['#969696'],
  vAxis: {
    textStyle: {
      color: '#2a2a2a'
    },
    baselines: {
      color: 'transparent'
    },
    gridlines: {
      color: 'transparent'
    },
    minorGridlines: {
      color: 'transparent'
    },
    textPosition: 'none'
  },
  vAxes: {
    0: {
      titleTextStyle: {
        color: '#2a2a2a',
        italic: false,
        fontSize: 14
      },
      gridlines: {
        color: 'transparent'
      }
    },
    1: {
      titleTextStyle: {
        color: 'white',
        italic: false,
        fontSize: 14
      },
      gridlines: {
        color: 'transparent'
      },
      color: '#F5A623',
      lineWidth: 4,
      pointSize: 12
    }
  }
};

function getMaxValue(data) {
  let maxValue = -1;
  let minValue = 100000;
  data.forEach((dataPoint, index) => {
    if (index !== 0) {
      maxValue = Math.max(maxValue, dataPoint[1]);
      minValue = Math.min(minValue, dataPoint[1]);
    }
  });
  return { maxValue, minValue };
}

export default class GraphTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrowStep: null
    };
  }

  handlePreviousArrow = () => {
    if (this.state.arrowStep !== null && this.state.arrowStep !== 0) {
      this.setState({ arrowStep: this.state.arrowStep - 4 });
    }
  };

  componentWillMount() {
    if (typeof window !== 'undefined') {
      if (window.innerWidth < 768) {
        this.setState({ arrowStep: 8 });
      }
    }
  }

  handleNextArrow = () => {
    if (this.state.arrowStep !== null && this.state.arrowStep !== 8) {
      this.setState({ arrowStep: this.state.arrowStep + 4 });
    }
  };

  render() {
    if (this.props.data && this.props.data[0][5]) {
      options.series = { 1: { type: 'line', targetAxisIndex: 1, ...options.vAxes[1] } };
    }
    if (this.props.isUserLoggedIn === false && this.props.activeTab === 'rates') {
      options.tooltip.trigger = 'none';
    } else {
      options.tooltip.trigger = '';
    }
    let oldestDate = transformDate(allDates[0]);
    let transformData = this.props.data.slice(0);
    transformData = [this.props.data[0], ...this.props.data.slice(constants.MONTH_CONSTANT)];
    if (this.state.arrowStep !== null) {
      transformData = [this.props.data[0], ...this.props.data.slice(constants.MONTH_CONSTANT)];
      if (this.state.arrowStep === 0) {
        transformData = [transformData[0], ...transformData.slice(1, 4)];
      } else if (this.state.arrowStep === 4) {
        transformData = [transformData[0], ...transformData.slice(this.state.arrowStep, this.state.arrowStep + 5)];
      } else {
        transformData = [transformData[0], ...transformData.slice(this.state.arrowStep + 1, this.state.arrowStep + 6)];
      }
    }
    let maxMinValue = getMaxValue(transformData);
    options.vAxes[0].viewWindow = {
      max: maxMinValue.maxValue + (maxMinValue.maxValue - maxMinValue.minValue),
      min: maxMinValue.minValue - (maxMinValue.maxValue - maxMinValue.minValue)
    };
    const { activeTab, isUserLoggedIn } = this.props;
    let graphLabels = [];
    transformData.forEach((graphData, index) => {
      if (index !== 0) {
        graphLabels.push(graphData[0]);
      }
    });
    return (
      <div style={{ position: 'relative' }}>
        <DirArrow className='pre'>
          <ChevronIcon onClick={this.handlePreviousArrow} />
        </DirArrow>
        <GraphHead>
          <div className='graphCntnt'>
            <span>
              {iffun(this.props.activeTab === 'rates', 'Rates per ton-km', `Indices pegged to ${oldestDate}`)}
            </span>
            <span>{iffun(this.props.showFuelRates === true, () => ` | Diesel prices per litre`, null)}</span>
          </div>
          <CheckboxWrapper>
            <input type='checkbox' onChange={this.props.toggleFuelPrices} checked={this.props.showFuelRates} />
            <span className='checkmark' />
            <i className='legend' />
            <span>Fuel rate</span>
          </CheckboxWrapper>
        </GraphHead>
        <Chart
          chartType='ComboChart'
          data={transformData}
          options={options}
          getChartWrapper={(chartWrapper) => this.props.chartWrapper(chartWrapper)}
          chartEvents={[
            {
              eventName: 'select',
              callback: function() {
                if (activeTab === 'rates' && isUserLoggedIn === false) {
                  document.getElementById('loginButton').click();
                }
              }
            }
          ]}
        />
        <GraphLabels>
          {graphLabels.map((value, index) => {
            return (
              <div key={index} style={{ fontSize: '10px', minWidth: '32px' }}>
                {value}
              </div>
            );
          })}
        </GraphLabels>
        <DirArrow className='next'>
          <ChevronIcon onClick={this.handleNextArrow} />
        </DirArrow>
      </div>
    );
  }
}

GraphTest.propTypes = {
  data: PropTypes.array,
  maxMinRates: PropTypes.object,
  toggleFuelPrices: PropTypes.func,
  showFuelRates: PropTypes.bool,
  activeTab: PropTypes.string,
  isUserLoggedIn: PropTypes.bool,
  chartWrapper: PropTypes.func
};

GraphTest.testProps = [
  {
    data: [
      ['Year', 'Spot Rate', { type: 'number', role: 'annotation' }],
      ['Apr 18', 2.92259, 2.92259],
      ['May 18', 2.92259, 2.92259],
      ['Jun 18', 2.60597, 2.60597],
      ['Jul 18', 2.5989, 2.5989],
      ['Aug 18', 2.62255, 2.62255],
      ['Sep 18', 2.73348, 2.73348],
      ['Oct 18', 2.60891, 2.60891],
      ['Nov 18', 2.59579, 2.59579],
      ['Dec 18', 2.59579, 2.59579],
      ['Jan 19', 2.59579, 2.59579],
      ['Feb 19', 2.59579, 2.59579]
    ]
  }
];
