import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GraphTest from './GraphTest';

const KeyLaneCard = ({ keyLaneData, onClick }) => (
  <div className='lane-content' onClick={onClick}>
    <div className='lft'>
      <h4>Lane details</h4>
      <ul className='location-drop-wrapper'>
        <li>
          <cite />
          <content>
            <strong>{keyLaneData.startLocation}</strong>
            <span>{keyLaneData.startLocationState}</span>
          </content>
        </li>
        <li>
          <cite />
          <content>
            <strong>{keyLaneData.endLocation}</strong>
            <span>{keyLaneData.endLocationState}</span>
          </content>
        </li>
      </ul>
      <label>Vehicle type</label>
      <p>
        {keyLaneData.truckType} &#183; {keyLaneData.truckTypeGroup}
      </p>
      <label>Market rate</label>
      <p>{keyLaneData.price}</p>
    </div>
    <div className='rgt watermark'>
      <GraphTest />
    </div>
  </div>
);

KeyLaneCard.propTypes = {
  keyLaneData: PropTypes.object,
  onClick: PropTypes.func
};

export default class KeyLanes extends Component {
  constructor(props) {
    super(props);

    this.fetchKeyLanesData = this.fetchKeyLanesData.bind(this);
  }

  componentDidMount() {
    this.fetchKeyLanesData();
  }

  fetchKeyLanesData() {
    //make api call here and return that data
    return [
      // {
      //   startLocation: 'Vishakapatnam',
      //   startLocationState: 'Andhra Pradesh',
      //   endLocation: 'Vishakapatnam',
      //   endLocationState: 'Maharashtra',
      //   truckType: 'Open',
      //   truckTypeGroup: '10 tyre',
      //   price: '₹28,600'
      // },
      // {
      //   startLocation: 'Vishakapatnam',
      //   startLocationState: 'Andhra Pradesh',
      //   endLocation: 'Vishakapatnam',
      //   endLocationState: 'Maharashtra',
      //   truckType: 'Open',
      //   truckTypeGroup: '10 tyre',
      //   price: '₹28,600'
      // },
      // {
      //   startLocation: 'Vishakapatnam',
      //   startLocationState: 'Andhra Pradesh',
      //   endLocation: 'Vishakapatnam',
      //   endLocationState: 'Maharashtra',
      //   truckType: 'Open',
      //   truckTypeGroup: '10 tyre',
      //   price: '₹28,600'
      // },
      // {
      //   startLocation: 'Vishakapatnam',
      //   startLocationState: 'Andhra Pradesh',
      //   endLocation: 'Vishakapatnam',
      //   endLocationState: 'Maharashtra',
      //   truckType: 'Open',
      //   truckTypeGroup: '10 tyre',
      //   price: '₹28,600'
      // }
    ];
  }

  render() {
    let keyLanesData = this.fetchKeyLanesData();

    return (
      <>
        <div className='lane-wrapper'>
          <p className='sec-head'>Key lanes</p>
          {keyLanesData.map((keyLaneData, index) => {
            return (
              <KeyLaneCard
                key={index}
                keyLaneData={keyLaneData}
                onClick={() => {
                  console.error('clicked..');
                }}
              />
            );
          })}
        </div>
      </>
    );
  }
}
