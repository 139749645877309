import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import iffun from '@bit/rivigo.ui.utils.iffun';
//TODO - we can move to our lib.
import PropEditor from './PropEditor';

const defaultContainerStyle = {
  display: 'flex',
  border: '1px solid lightblue'
};
const outerContainerStyle = {
  //border: '1px solid #5d2020',
  padding: 20,
  marginBottom: 10
};
const Bold = styled.div`
  font-weight: bold;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  position: relative;
  flex-direction: column;
`;

export default class ComponentLoader extends Component {
  static propTypes = {
    Comp: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.object.isRequired])
  };
  render() {
    const Comp = this.props.Comp;
    return (
      <Container>
        <Bold>{Comp.name}</Bold>
        {iffun(
          Comp.testProps !== undefined && Comp.testProps.length > 0,
          () => {
            return Comp.testProps.map(function(props, i) {
              return (
                <div key={i} style={{ ...outerContainerStyle }}>
                  <PropEditor p={props} />
                  <div style={{ ...defaultContainerStyle, ...props.containerStyle }}>
                    <Comp {...props} />
                  </div>
                </div>
              );
            });
          },
          () => {
            return (
              <div
                style={{
                  ...defaultContainerStyle,
                  ...{ display: 'flex', overflow: 'auto', flexGrow: 1, height: '100%' }
                }}>
                <Comp />
              </div>
            );
          }
        )}
      </Container>
    );
  }
}

// TODO - eXplore - https://github.com/storybooks/storybook
//TODO - create ComponentPropTableGenerator Ex -
// http://www.episodeyang.com/react-component-props-table/
// https://www.npmjs.com/package/terra-props-table

/*function renderPropListOfComponent(Comp) {
  return (
    typeof Comp.propTypes === 'object' && (
      <div>
        <h1>Props expected: </h1>
        <div>
          {Object.keys(Comp.propTypes).map((keyName, index) => {
            return <li key={index}>{keyName}</li>;
          })}
        </div>
      </div>
    )
  );
}*/
