import React, { Component } from 'react';
import PropTypes from 'prop-types';
import iffun from '@bit/rivigo.ui.utils.iffun';
import Autosuggest from 'react-autosuggest';
import { liveRatesServices } from '../../services';

export default class AutoSuggestWrapper extends Component {
  state = {
    value: '',
    suggestions: [],
    showHideIcon: false
  };

  /**
   * This function is triggered everytime we type in the autosuggest input
   */
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      showHideIcon: false
    });
    if (newValue.length === 0) {
      this.props.onChange(null);
    }
  };

  /**
   * This is called when we select a suggestion from dropdown list
   */
  onSuggestionSelected = (event, { suggestion }) => {
    this.setState({ showHideIcon: true });
    this.props.onChange(suggestion);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value }) => {
    if (value.length >= 3) {
      liveRatesServices
        .getLiveRatesSuggestions(value)
        .then((res) => {
          this.setState({
            suggestions: res.predictions.map((p, idx) => ({
              name: p.description,
              placeId: p.placeId,
              index: idx
            }))
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  clearSearch = () => {
    this.setState({
      value: '',
      showHideIcon: false
    });
    this.props.onChange(null);
  };

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: this.props.placeholder,
      value,
      onChange: this.onChange
    };

    return (
      <div className='dropWrapper'>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={(suggestion) => suggestion.name}
          renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
          inputProps={inputProps}
        />
        {iffun(
          this.state.showHideIcon === true,
          () => (
            <i onClick={this.clearSearch} className='close-icon'>
              &#10005;
            </i>
          ),
          null
        )}
      </div>
    );
  }
}

AutoSuggestWrapper.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};
