import iffun from '@bit/rivigo.ui.utils.iffun';
import fuelPriceData from '../data/fuelPrices.json';
import spotPriceData from '../data/ratesDataNew.json';
import weightPerTruck from '../data/weightPerTruck.json';
import topLanesData from '../data/TopLanesNew.json';
import constants from '../constants/jsonDataConstants';

let weightData;
let spotRateData = {};
let fuelData;
let topLanesSpotRateData = {};

function getFormattedPrice(price) {
  let priceString = price.toString();
  let lastThree = priceString.substring(priceString.length - 3);
  let otherNumbers = priceString.substring(0, priceString.length - 3);
  if (otherNumbers !== '') {
    lastThree = `,${lastThree}`;
  }
  let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;

  return `₹${res}`;
}

function getAllDates() {
  let allDates = [];
  Object.keys(spotPriceData[0]).forEach((key, index) => {
    if (index > 3) {
      allDates.push(key);
    }
  });
  return allDates;
}

function oldestDate() {
  let allDates = getAllDates();
  return allDates[0];
}

function latestDate() {
  let allDates = getAllDates();
  return allDates[allDates.length - 1];
}

function currDatePrevYear() {
  let allDates = getAllDates();
  return allDates[constants.MONTH_CONSTANT];
}

function getWeightFromMap(a, b, c, d) {
  if (d) {
    return Number(weightData[a][b][c][d].value);
  } else if (c) {
    return Number(weightData[a][b][c].value);
  } else if (b) {
    return Number(weightData[a][b].value);
  } else if (a) {
    return Number(weightData[a].value);
  }
}

function getAbsoluteValue(a, b, c, d, date, data) {
  if (data[a] && data[a][b] && data[a][b][c] && data[a][b][c][d]) {
    return Number(data[a][b][c][d][date]);
  } else {
    return 0;
  }
}

function getDistanceObject() {
  let obj = {};

  constants.AVAILABLE_DISTANCE.forEach((distance, index) => {
    if (index !== 0) {
      obj[distance] = {};
    }
  });
  return obj;
}

function makeZoneToDistanceBand() {
  let obj = {};

  constants.AVAILABLE_SOURCE_ZONES.forEach((zone, index) => {
    if (index !== 0) {
      obj[zone] = getDistanceObject();
    }
  });
  return obj;
}

function transformRateData() {
  constants.AVAILABLE_TRUCK_TYPE.forEach((truckType, index) => {
    if (index !== 0) {
      spotRateData[truckType] = {};
      constants.AVAILABLE_TRUCK_TYPE_GROUPS[index].forEach((truckTypeGroup) => {
        spotRateData[truckType][truckTypeGroup] = makeZoneToDistanceBand();
      });
    }
  });

  let allDates = getAllDates();

  spotPriceData.forEach((element) => {
    allDates.forEach((date) => {
      spotRateData[element[constants.TRUCK_TYPE]][element[constants.TRUCK_TYPE_GROUP]][element[constants.SOURCE_ZONE]][
        element[constants.DISTANCE_BAND]
      ][date] = element[date];
    });
  });
  return spotRateData;
}

function trasnformWeightData() {
  weightData = {};
  constants.AVAILABLE_TRUCK_TYPE.forEach((truckType, index) => {
    if (index !== 0) {
      weightData[truckType] = {};
      constants.AVAILABLE_TRUCK_TYPE_GROUPS[index].forEach((truckTypeGroup) => {
        weightData[truckType][truckTypeGroup] = makeZoneToDistanceBand();
      });
    }
  });

  weightPerTruck.reverse().forEach((element) => {
    if (element[constants.TRUCK_TYPE_GROUP] === '') {
      weightData[element[constants.TRUCK_TYPE]].value = element.Weight;
    } else if (element[constants.SOURCE_ZONE] === '') {
      weightData[element[constants.TRUCK_TYPE]][element[constants.TRUCK_TYPE_GROUP]].value = element.Weight;
    } else if (element[constants.DISTANCE_BAND] === '') {
      weightData[element[constants.TRUCK_TYPE]][element[constants.TRUCK_TYPE_GROUP]][
        element[constants.SOURCE_ZONE]
      ].value = element.Weight;
    } else {
      weightData[element[constants.TRUCK_TYPE]][element[constants.TRUCK_TYPE_GROUP]][element[constants.SOURCE_ZONE]][
        element[constants.DISTANCE_BAND]
      ].value = element.Weight;
    }
  });

  return weightData;
}

function transformDate(date) {
  let formattedDate = '';
  date
    .split('-')
    .reverse()
    .forEach((value, index) => {
      if (index === 1) {
        formattedDate += constants.MONTHS[Number(value) - 1];
      } else if (index === 2) {
        formattedDate += `-${value.substr(2)}`;
      }
    });
  return formattedDate;
}

function transformFuelData() {
  fuelData = [];
  fuelPriceData.forEach((element) => {
    fuelData.push({
      northFuelRate: element[constants.NORTH],
      southFuelRate: element[constants.SOUTH],
      westFuelRate: element[constants.WEST],
      eastFuelRate: element[constants.EAST],
      allIndiaFuelRate: element[constants.ALL_INDIA]
    });
  });
  return fuelData;
}

function getValuesFromB(filterObject, date, data) {
  let weightSum = 0,
    result = {};
  if (filterObject.b === constants.ALL) {
    let denominator = 0;
    Object.keys(data[filterObject.a]).forEach((val) => {
      let tempObj = { a: filterObject.a, b: val, c: filterObject.c, d: filterObject.d };
      weightSum +=
        getValuesFromC(tempObj, date, data).value *
        getValuesFromC(tempObj, date, data).weight *
        getWeightFromMap(filterObject.a, val);
      denominator += getValuesFromC(tempObj, date, data).weight * getWeightFromMap(filterObject.a, val);
    });
    result = {
      value: weightSum / denominator,
      weight: denominator
    };
  } else {
    // return values from bottom tree
    result = {
      value: getValuesFromC(filterObject, date, data).value,
      weight: getWeightFromMap(filterObject.a, filterObject.b) * getValuesFromC(filterObject, date, data).weight
    };
  }
  return result;
}

function getValuesFromC(filterObject, date, data) {
  let weightSum = 0,
    result = {};
  if (filterObject.c === constants.ALL) {
    let denominator = 0;
    Object.keys(data[filterObject.a][filterObject.b]).forEach((val) => {
      let tempObj = { a: filterObject.a, b: filterObject.b, c: val, d: filterObject.d };
      weightSum +=
        getValuesFromD(tempObj, date, data).value *
        getValuesFromD(tempObj, date, data).weight *
        getWeightFromMap(filterObject.a, filterObject.b, val);
      denominator += getValuesFromD(tempObj, date, data).weight * getWeightFromMap(filterObject.a, filterObject.b, val);
    });
    result = {
      value: weightSum / denominator,
      weight: denominator
    };
  } else {
    // return values from bottom tree
    result = {
      value: getValuesFromD(filterObject, date, data).value,
      weight:
        getWeightFromMap(filterObject.a, filterObject.b, filterObject.c) *
        getValuesFromD(filterObject, date, data).weight
    };
  }
  return result;
}

function getValuesFromD(filterObject, date, data) {
  let weightSum = 0,
    result = {};
  let filterValue = filterObject.d;
  if (filterValue === constants.ALL) {
    Object.keys(data[filterObject.a][filterObject.b][filterObject.c]).forEach((val) => {
      weightSum +=
        getAbsoluteValue(filterObject.a, filterObject.b, filterObject.c, val, date, data) *
        getWeightFromMap(filterObject.a, filterObject.b, filterObject.c, val);
    });
    result = {
      value: weightSum,
      weight: 1
    };
  } else {
    // return values from bottom tree
    result = {
      value: getAbsoluteValue(filterObject.a, filterObject.b, filterObject.c, filterObject.d, date, data),
      weight: getWeightFromMap(filterObject.a, filterObject.b, filterObject.c, filterObject.d)
    };
  }
  return result;
}

function getSpotRate(filterObject, data = spotRateData) {
  let allResults = [];

  filterObject.e.forEach((date) => {
    let weightSum = 0,
      result = {};
    if (filterObject.a === constants.ALL) {
      let denominator = 0;
      Object.keys(data).forEach((val) => {
        let tempObj = { a: val, b: filterObject.b, c: filterObject.c, d: filterObject.d };
        weightSum +=
          getValuesFromB(tempObj, date, data).value *
          getValuesFromB(tempObj, date, data).weight *
          getWeightFromMap(val);
        denominator += getValuesFromB(tempObj, date, data).weight * getWeightFromMap(val);
      });
      result = weightSum / denominator;
    } else {
      // return values from bottom tree
      result = getValuesFromB(filterObject, date, data).value;
    }
    allResults.push({ [date]: result });
  });

  return allResults;
}

function getTooltipHtml(result, date, title, unit, momValue) {
  let color = '#19b985';
  if (Number(momValue) <= 0) {
    color = '#e3383a';
  }
  let formattedResult;
  if (title === 'Index') {
    formattedResult = result;
  } else {
    formattedResult = `₹${result}`;
  }
  return `<div style="position: relative; width: 200px; background: #ffffff; border-radius: 4px; position: relative; padding: 12px; font-style: normal; box-shadow: -1px -1px 4px 1px rgba(0, 0, 0, 0.2); position: relative;">
  <p style="font-size: 12px; font-weight: 700; margin-bottom: 10px; color: #2a2a2a;">${date}</p>
  <p style="font-size: 14px; padding-bottom: 5px; color: #969696;">${title}</p>
  <p style="font-size: 16px; margin-bottom: 12px; color: #2a2a2a;">${formattedResult} <span style="font-size: 12px; padding-left: 5px;">${unit}</span></p>
  <p style="display: flex; width: 100%;flex-direction: row;">
    <span style="flex-grow:1;">
      <em style="display: block; font-style: normal; color: #969696; font-size: 12px; margin-bottom: 2px;">M.O.M.</em>
      <i style="display: block; font-style: normal; color: ${color}; font-size: 14px; font-weight: 700;">${momValue}%</i>
    </span>
  </p>
  </div>`;
}

function getGraphData(filterObject) {
  let graphData = [];
  if (filterObject.showFuelRates === true) {
    graphData.push([
      'Year',
      'Spot Rate',
      { type: 'string', role: 'tooltip', p: { html: true } },
      { role: 'style' },
      'Diesel Rate',
      { type: 'string', role: 'tooltip', p: { html: true } },
      { type: 'string', role: 'annotation' },
      { role: 'style' }
    ]);
  } else {
    graphData.push([
      'Year',
      'Spot Rate',
      { type: 'string', role: 'annotation' },
      { type: 'string', role: 'tooltip', p: { html: true } },
      { role: 'style' }
    ]);
  }
  filterObject.allResults.forEach((result, index) => {
    Object.keys(result).forEach((date) => {
      let transformedDate = transformDate(date);
      let previousSpotRate;
      if (index === 0) {
        let prevDate = Object.keys(filterObject.allResults[index])[0];
        previousSpotRate = filterObject.allResults[index][prevDate];
      } else {
        let prevDate = Object.keys(filterObject.allResults[index - 1])[0];
        previousSpotRate = filterObject.allResults[index - 1][prevDate];
      }
      let momValue = Number(((Number(result[date]) - Number(previousSpotRate)) / Number(previousSpotRate)) * 100);
      let fuelRate;
      let prevMonthFuelRate;
      if (filterObject.zoneId === 4) {
        fuelRate = Number(fuelData[index].allIndiaFuelRate);
        if (index === 0) {
          prevMonthFuelRate = fuelRate;
        } else {
          prevMonthFuelRate = Number(fuelData[index - 1].allIndiaFuelRate);
        }
      } else if (filterObject.zoneId === 0) {
        fuelRate = Number(fuelData[index].northFuelRate);
        if (index === 0) {
          prevMonthFuelRate = fuelRate;
        } else {
          prevMonthFuelRate = Number(fuelData[index - 1].northFuelRate);
        }
      } else if (filterObject.zoneId === 1) {
        fuelRate = Number(fuelData[index].southFuelRate);
        if (index === 0) {
          prevMonthFuelRate = fuelRate;
        } else {
          prevMonthFuelRate = Number(fuelData[index - 1].southFuelRate);
        }
      } else if (filterObject.zoneId === 2) {
        fuelRate = Number(fuelData[index].westFuelRate);
        if (index === 0) {
          prevMonthFuelRate = fuelRate;
        } else {
          prevMonthFuelRate = Number(fuelData[index - 1].westFuelRate);
        }
      } else if (filterObject.zoneId === 3) {
        fuelRate = Number(fuelData[index].eastFuelRate);
        if (index === 0) {
          prevMonthFuelRate = fuelRate;
        } else {
          prevMonthFuelRate = Number(fuelData[index - 1].eastFuelRate);
        }
      }

      let momFuelValue = ((fuelRate - prevMonthFuelRate) / prevMonthFuelRate) * 100;

      if (filterObject.showFuelRates === true) {
        let computedRateAnnotation = `₹${fuelRate.toFixed(2)}`;
        let tooltipHtmlSpotRate = getTooltipHtml(
          result[date].toFixed(2),
          transformedDate,
          'Rate',
          'per ton-km',
          momValue.toFixed(2)
        );
        let tooltipHtmlFuelRate = getTooltipHtml(
          fuelRate.toFixed(2),
          transformedDate,
          'Diesel rate',
          'per-litre',
          momFuelValue.toFixed(2)
        );
        let colorVar = '';
        if (index === filterObject.allResults.length - 1) {
          colorVar = 'color: #606062';
        }
        graphData.push([
          transformedDate,
          result[date],
          tooltipHtmlSpotRate,
          colorVar,
          fuelRate,
          tooltipHtmlFuelRate,
          computedRateAnnotation,
          ''
        ]);
      } else {
        let computedRateAnnotation = `₹${result[date].toFixed(2)}`;
        let tooltipHtml = getTooltipHtml(
          result[date].toFixed(2),
          transformedDate,
          'Rate',
          'per ton-km',
          momValue.toFixed(2)
        );
        if (filterObject.isUserLoggedIn === false) {
          computedRateAnnotation = '🔒';
        }
        if (index === filterObject.allResults.length - 1) {
          graphData.push([transformedDate, result[date], `₹${result[date].toFixed(2)}`, tooltipHtml, 'color: #606062']);
        } else if (index === constants.MONTH_CONSTANT) {
          graphData.push([transformedDate, result[date], `₹${result[date].toFixed(2)}`, tooltipHtml, '']);
        } else {
          graphData.push([transformedDate, result[date], computedRateAnnotation, tooltipHtml, '']);
        }
      }
    });
  });
  return {
    graphData
  };
}

function transformGraphDataToIndices(allGraphData) {
  let allDates = getAllDates();
  let modifiedGraphData = Object.assign({}, allGraphData);
  if (modifiedGraphData.graphData) {
    let baseIndex = Number(allGraphData.graphData[1][1]);
    allGraphData.graphData.forEach((dataPoint, index) => {
      if (index !== 0) {
        modifiedGraphData.graphData[index][1] = (dataPoint[1] / baseIndex) * 100;
        let previousIndex = Number(modifiedGraphData.graphData[1][1]);
        if (index > 1) {
          previousIndex = Number(modifiedGraphData.graphData[index - 1][1]);
        }
        let mom = ((modifiedGraphData.graphData[index][1] - previousIndex) / previousIndex) * 100;
        if (!modifiedGraphData.graphData[0][5]) {
          modifiedGraphData.graphData[index][3] = getTooltipHtml(
            modifiedGraphData.graphData[index][1].toFixed(2),
            modifiedGraphData.graphData[index][0],
            'Index',
            `pegged to ${transformDate(allDates[0])}`,
            mom.toFixed(2)
          );
          modifiedGraphData.graphData[index][2] = modifiedGraphData.graphData[index][1].toFixed(2);
        } else {
          modifiedGraphData.graphData[index][2] = getTooltipHtml(
            modifiedGraphData.graphData[index][1].toFixed(2),
            modifiedGraphData.graphData[index][0],
            'Index',
            `pegged to ${transformDate(allDates[0])}`,
            mom.toFixed(2)
          );
          let previousFuelRate = modifiedGraphData.graphData[index][4];
          if (index > 1) {
            previousFuelRate = modifiedGraphData.graphData[index - 1][4];
          }
          let momFuelRate = ((modifiedGraphData.graphData[index][4] - previousFuelRate) / previousFuelRate) * 100;

          modifiedGraphData.graphData[index][5] = getTooltipHtml(
            modifiedGraphData.graphData[index][4].toFixed(2),
            modifiedGraphData.graphData[index][0],
            'Diesel rate',
            'per-litre',
            momFuelRate.toFixed(2)
          );
          modifiedGraphData.graphData[index][6] = `₹${modifiedGraphData.graphData[index][4].toFixed(2)}`;
        }
      }
    });
  }
  return modifiedGraphData;
}

// Top Lanes Rate Utility functions start
//*************************************************************//

function transformTopLanesData() {
  constants.AVAILABLE_TRUCK_TYPE.forEach((truckType, index) => {
    if (index !== 0) {
      topLanesSpotRateData[truckType] = {};
      constants.AVAILABLE_TRUCK_TYPE_GROUPS[index].forEach((truckTypeGroup) => {
        topLanesSpotRateData[truckType][truckTypeGroup] = makeZoneToDistanceBand();
      });
    }
  });

  topLanesData.forEach((element) => {
    if (
      Object.keys(
        topLanesSpotRateData[element[constants.TRUCK_TYPE]][element[constants.TRUCK_TYPE_GROUP]][
          element[constants.SOURCE_ZONE]
        ][element[constants.DISTANCE_BAND]]
      ).length === 0
    ) {
      topLanesSpotRateData[element[constants.TRUCK_TYPE]][element[constants.TRUCK_TYPE_GROUP]][
        element[constants.SOURCE_ZONE]
      ][element[constants.DISTANCE_BAND]] = [];
    }
    topLanesSpotRateData[element[constants.TRUCK_TYPE]][element[constants.TRUCK_TYPE_GROUP]][
      element[constants.SOURCE_ZONE]
    ][element[constants.DISTANCE_BAND]].push({
      [constants.WEIGHT_SCORE]: element[constants.WEIGHT_SCORE],
      [constants.ODVT]: element[constants.ODVT],
      [constants.LANE_ID]: element[constants.LANE_ID]
    });
  });
}

function filterTopLane(laneData, filterParam, tempFilterObject) {
  return laneData[filterParam] === tempFilterObject[filterParam];
}

function sortArrayByKey(array, key) {
  return array.sort(function(a, b) {
    var x = a[key];
    var y = b[key];
    return iffun(x < y, 1, iffun(x > y, -1, 0));
  });
}

function getTopLanes(filter) {
  let finalArr = topLanesData;

  if (filter[constants.DISTANCE_BAND] !== 'all') {
    finalArr = finalArr.filter((laneData) => filterTopLane(laneData, constants.DISTANCE_BAND, filter));
  }

  if (filter[constants.SOURCE_ZONE] !== 'all') {
    finalArr = finalArr.filter((laneData) => filterTopLane(laneData, constants.SOURCE_ZONE, filter));
  }

  if (filter[constants.TRUCK_TYPE_GROUP] !== 'all') {
    finalArr = finalArr.filter((laneData) => filterTopLane(laneData, constants.TRUCK_TYPE_GROUP, filter));
  }

  if (filter[constants.TRUCK_TYPE] !== 'all') {
    finalArr = finalArr.filter((laneData) => filterTopLane(laneData, constants.TRUCK_TYPE, filter));
  }

  finalArr = sortArrayByKey(finalArr, constants.WEIGHT_SCORE);
  return iffun(finalArr.length > 4, finalArr.slice(0, 4), finalArr);
}

// Top Lanes Rate Utility functions end
//*************************************************************//

// Live Rate Data Utility functions start
//*************************************************************//

function transformLiveRateData(liveRateData) {
  let liveRateDataArr = liveRateData.map((liveRate) => {
    return {
      [constants.TRUCK_TYPE]: liveRate.l2Value,
      [constants.TRUCK_TYPE_GROUP]: liveRate.l3Value,
      [constants.SOURCE_ZONE]: liveRate.l4Value,
      [constants.DISTANCE_BAND]: liveRate.l5Value,
      ['Live Rate']: liveRate.priceIndex,
      lastUpdated: liveRate.lastUpdatedAtEpoch
    };
  });

  let liveRateDataObj = {};

  constants.AVAILABLE_TRUCK_TYPE.forEach((truckType, index) => {
    if (index !== 0) {
      liveRateDataObj[truckType] = {};
      constants.AVAILABLE_TRUCK_TYPE_GROUPS[index].forEach((truckTypeGroup) => {
        liveRateDataObj[truckType][truckTypeGroup] = makeZoneToDistanceBand();
      });
    }
  });

  liveRateDataArr.forEach((element) => {
    liveRateDataObj[element[constants.TRUCK_TYPE]][element[constants.TRUCK_TYPE_GROUP]][element[constants.SOURCE_ZONE]][
      element[constants.DISTANCE_BAND]
    ]['Live Rate'] = element['Live Rate'];
  });

  return liveRateDataObj;
}

// Live Rate Data Utility functions start
//*************************************************************//

export {
  getWeightFromMap,
  getAbsoluteValue,
  transformTopLanesData,
  transformRateData,
  trasnformWeightData,
  transformDate,
  transformFuelData,
  transformLiveRateData,
  getValuesFromB,
  getValuesFromC,
  getValuesFromD,
  getSpotRate,
  getGraphData,
  transformGraphDataToIndices,
  getTopLanes,
  getAllDates,
  getFormattedPrice,
  oldestDate,
  latestDate,
  currDatePrevYear
};
