import styled from 'styled-components';
import { device } from '../constants/device';

const LiveRateItem = styled.div`
  display: flex;
  align-items: center;
  min-width: 320px;
  padding: 0 16px;
  line-height: 1;
  border-right: 1px solid #969696;
  border-radius: 0;
  background: none;
  justify-content: space-between;
  height: 48px;
  font-size: 14px;
  font-weight: 700;
  p {
    white-space: nowrap;
  }
  span {
    font-weight: 700;
  }
  em {
    color: #969696;
    font-size: 12px;
    font-style: normal;
    margin: 0 10px;
  }
  p:first-of-type {
    padding-right: 15px;
    svg {
      font-size: 6px;
      vertical-align: middle;
    }
  }
  @media ${device.mobileL} {
    height: 32px;
  }
`;

export default LiveRateItem;
