import React, { Component } from 'react';
import iffun from '@bit/rivigo.ui.utils.iffun';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CrossIcon, ContainerTruckIcon, OpenTruckIcon, TrailerTruckIcon } from '../custom-icons';
import { DialogWrapper, Dialog } from '../components-pure/Dialog';
import { device } from '../constants/device';
import { getFormattedPrice } from '../utils/csvDataManipulation';
import TopLaneGraph from './TopLaneGraph';

const StyledDialog = styled(Dialog)`
  max-width: 480px;
  padding: 0;
  h5 {
    font-size: 18px;
    padding: 12px 20px;
    color: #2a2a2a;
  }
  .currentrate {
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid #d8d8d8;
  }
  .graphWrapper {
    padding: 20px;
    background: #ededed;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media ${device.tablet} {
    padding: 0;
  }
`;

const TopLaneTruckDetails = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  align-items: center;
  & > div {
    flex-grow: 1;
  }
  .leftPane {
    width: 55%;
    max-width: 55%;
    border-right: 1px solid #d8d8d8;
    padding: 10px 20px;
  }
  .rightPane {
    padding: 15px 20px;
    text-align: center;
    .top {
      font-size: 12px;
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      svg {
        margin: 3px 30px 0 0;
        color: #2a2a2a;
        font-size: 30px;
      }
    }
  }
  .location-drop-wrapper {
    padding-left: 0;
    position: relative;
    z-index: 1;
    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      color: #969696;
      position: relative;
      height: 50px;
      &:after,
      &:before {
        content: '';
        position: absolute;
        height: 50%;
        width: 1px;
        // background: #969696;
        border-left: 1px dashed #969696;
        left: 4px;
      }
      &:first-of-type {
        &:before {
          display: none;
        }
        cite {
          background: #19b985;
        }
      }
      &:last-of-type {
        &:after {
          display: none;
        }
        cite {
          background: #e3383a;
        }
      }
      &:after {
        bottom: 0;
      }
      &:before {
        top: 0;
      }
      cite {
        height: 9px;
        width: 9px;
        border-radius: 50%;
        z-index: 1;
      }
      content {
        flex-grow: 1;
        margin-left: 15px;
        color: $white-color;
        * {
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 220px;
        }
        strong {
          font-size: 12px;
          color: #2a2a2a;
        }
        span {
          font-size: 12px;
          margin-top: 2px;
          color: $dark-color4;
        }
      }
    }
  }
  &&& {
    @media ${device.tablet} {
      flex-direction: column;
      & > div {
        width: 100%;
        max-width: 100%;
      }
      .leftPane {
        border-right: 0;
        border-bottom: 1px solid #d8d8d8;
      }
      .rightPane {
        text-align: left;
        .top {
          svg {
            font-size: 24px;
            margin-right: 20px;
          }
        }
        .btm {
          font-size: 12px;
          padding-left: 45px;
        }
      }
    }
  }
`;
export default class TopLanesPopup extends Component {
  state = {
    isXhrInProgress: false
  };

  componentWillMount() {
    document.getElementsByTagName('body')[0].classList.add('noScroll');
  }

  componentWillUnmount() {
    document.getElementsByTagName('body')[0].classList.remove('noScroll');
  }

  render() {
    const { onCloseBtnClick, selectedLane, truckMappings, laneHistory } = this.props;
    return (
      <DialogWrapper>
        <StyledDialog>
          <h5>Top lanes</h5>
          <TopLaneTruckDetails>
            <div className='leftPane'>
              <ul className='location-drop-wrapper'>
                <li>
                  <cite />
                  <content>
                    <strong>{selectedLane.sourceAddress.split(',')[0]}</strong>
                    <span>{selectedLane.sourceAddress.split(',')[1]}</span>
                  </content>
                </li>
                <li>
                  <cite />
                  <content>
                    <strong>{selectedLane.destinationAddress.split(',')[0]}</strong>
                    <span>{selectedLane.destinationAddress.split(',')[1]}</span>
                  </content>
                </li>
              </ul>
            </div>
            <div className='rightPane'>
              <p className='top'>
                {iffun(truckMappings.typeBindings[selectedLane.vehicleTypeId] === 'Open', <OpenTruckIcon />, () =>
                  iffun(
                    truckMappings.typeBindings[selectedLane.vehicleTypeId] === 'Trailer',
                    <TrailerTruckIcon />,
                    <ContainerTruckIcon />
                  )
                )}
                {truckMappings.typeBindings[selectedLane.vehicleTypeId]}
              </p>
              <p className='btm'>
                {truckMappings.lengthBindings[selectedLane.vehicleLengthId]} &#183;{' '}
                {truckMappings.weightBindings[selectedLane.vehicleWeightId]}
              </p>
            </div>
          </TopLaneTruckDetails>
          <div className='currentrate'>
            <label>Current Market rate</label>
            <strong>{getFormattedPrice(selectedLane.marketPrice)}</strong>
          </div>
          <div className='graphWrapper'>
            <TopLaneGraph laneHistory={laneHistory} />
          </div>
          <cite onClick={onCloseBtnClick} className='closeIcon'>
            <CrossIcon />
          </cite>
        </StyledDialog>
      </DialogWrapper>
    );
  }
}

TopLanesPopup.propTypes = {
  onCloseBtnClick: PropTypes.func,
  selectedLane: PropTypes.object,
  truckMappings: PropTypes.object,
  laneHistory: PropTypes.array
};
