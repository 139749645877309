import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import iffun from '@bit/rivigo.ui.utils.iffun';
import Spinner from '@bit/rivigo.ui.spinner';
import { CrossIcon, CircleIcon } from '../custom-icons';
import { DialogWrapper, Dialog } from '../components-pure/Dialog';
import LiveRateItem from '../components-pure/LiveRateItem';
import AutoSuggestWrapper from './common/AutoSuggestWrapper';
import { RfiCta } from '../components/PrimaryCTA/PrimaryCTA';
import { device } from '../constants/device';
import userServices from '../services/userServices';

const FilterContainer = styled.div`
  &&& {
    .react-autosuggest__input--focused {
      outline: none;
    }

    .react-autosuggest__input--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .react-autosuggest__suggestions-container {
      display: none;
    }

    .react-autosuggest__suggestions-container--open {
      display: block;
      position: absolute;
      top: 42px;
      width: 100%;
      border: 1px solid #aaa;
      background-color: #f8f8f8;
      font-family: Helvetica, sans-serif;
      font-weight: 400;
      font-size: 12px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      max-height: 100px;
      overflow-y: auto;
      z-index: 2;
    }

    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 14px 20px;
    }

    .react-autosuggest__suggestion--highlighted {
      background-color: #ddd;
    }

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 0 -10px 20px;
    .col {
      padding: 0 10px;
      flex-grow: 1;
    }
    .react-autosuggest__container {
      background: #f5f5f5;
      border: 1px solid #c0c0c0;
      border-radius: 4px;
      position: relative;
    }
    .dropWrapper {
      position: relative;
      .close-icon {
        position: absolute;
        right: 1px;
        top: 0px;
        width: 42px;
        height: 42px;
        cursor: pointer;
        color: #969696;
        text-align: center;
        line-height: 42px;
        font-style: normal;
      }
      &.hideClose {
        .close-icon {
          display: none;
        }
      }
    }
    .react-autosuggest__input {
      padding: 10px 15px;
      height: 40px;
      border: none;
      box-shadow: none;
      background: none;
      color: #2a2a2a;
      width: 100%;
      line-height: 3.1em;
      border-radius: 0;
      display: block;
      font-size: 14px;
      transition: border-color ease-in-out 0.15s;

      &:focus {
        outline: none;
      }
      &::-webkit-input-placeholder {
        color: #969696;
      }
      &::-moz-placeholder {
        color: #969696;
      }
      &:-ms-input-placeholder {
        color: #969696;
      }
      &:-moz-placeholder {
        color: #969696;
      }
    }
    .react-autosuggest__suggestions-container {
      border: 0;
      background: #f5f5f5;
      box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      top: 52px;
    }
    .react-autosuggest__suggestions-container--open {
      max-height: 275px;
    }
    .react-autosuggest__suggestion {
      height: 55px;
      font-size: 14px;
      color: #2a2a2a;
      padding: 0px 15px;
      display: inline-flex;
      align-items: center;
      width: 100%;
      &:nth-of-type(2n) {
        background: #ffffff;
      }
      &:hover {
        background: #dddddd;
      }
    }
    label {
      color: #969696;
      font-size: 14px;
      margin: 0 0 6px;
      display: block;
    }
    @media ${device.tablet} {
      flex-direction: column;
      align-items: flex-start;
      margin: 0 -10px;
      .col {
        width: 100%;
        margin-bottom: 15px;
        button {
          height: 36px;
        }
      }
      .react-autosuggest__input {
        height: 34px;
      }
      label {
        font-size: 12px;
      }
    }
  }
`;

const StyledDialog = styled(Dialog)`
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  padding: 0 0 30px;
  h5 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .topPane {
    padding: 30px 30px 0;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
  @media ${device.tablet} {
    padding: 0 0 16px;
    h5 {
      font-size: 16px;
      margin-bottom: 10px;
    }
    .topPane {
      padding: 16px 16px 0;
    }
  }
`;

const NoResultDiv = styled.div`
  min-height: 350px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RateWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 20px 30px 0px;
  @media ${device.tablet} {
    padding: 16px 16px 0;
  }
`;
const StyledLiveRateItem = styled(LiveRateItem)`
  background: #f1f1f1;
  border-radius: 4px;
  max-width: 2000px;
  border: 0;
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0;
  }
  p {
    :last-of-type {
      font-size: 16px;
      svg {
        color: #e3383a;
        margin-left: 15px;
      }
    }
    :first-of-type {
      span {
        margin-right: 15px;
      }
    }
  }
  span,
  em {
    max-width: 150px;
    margin: 0 5px;
  }
  span {
    font-weight: 700;
  }
  @media ${device.tablet} {
    height: 48px;
    min-width: 100%;
    padding: 0 12px;
    p:first-of-type {
      max-width: 150px;
      span {
        display: block;
        margin: 0 0 3px 0;
      }
      em:first-of-type {
        margin-left: 0;
      }
    }
    p:last-of-type {
      font-size: 14px;
    }
  }
`;

const StyledRfiCta = styled(RfiCta)`
  &&& {
    height: 42px;
  }
`;
export default class LiveFeedPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromLocation: null,
      toLocation: null
    };
    this.element = React.createRef();
  }

  handleScrollEvent = (event) => {
    var element = event.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      this.props.getMoreLiveFeed();
    }
  };

  handleFromLocationChange = (fromLocation) => {
    this.setState({ fromLocation: fromLocation });
  };

  handleToLocationChange = (toLocation) => {
    this.setState({ toLocation: toLocation });
  };

  handleFiltersApply = () => {
    const { fromLocation, toLocation } = this.state;
    let formattedLabel = '';
    if (fromLocation) {
      formattedLabel += `From: ${fromLocation.name}`;
    }
    if (toLocation) {
      formattedLabel += `, To: ${toLocation.name}`;
    }
    userServices.sendAnalyticsEvents({
      category: 'VRE',
      action: 'Clicks APPLY',
      label: `${formattedLabel}`
    });
    this.props.handleFiltersApply(fromLocation, toLocation);
  };

  componentWillMount() {
    document.getElementsByTagName('body')[0].classList.add('noScroll');
  }

  componentDidMount() {
    this.element.current.addEventListener('scroll', (event) => this.handleScrollEvent(event));
    this.props.getMoreLiveFeed();
  }

  componentWillUnmount() {
    this.element.current.removeEventListener('scroll', (event) => this.handleScrollEvent(event));
    document.getElementsByTagName('body')[0].classList.remove('noScroll');
  }

  render() {
    const { onCloseBtnClick, liveFeed, truckMappings } = this.props;
    return (
      <DialogWrapper>
        <StyledDialog>
          <div className='topPane'>
            <h5>Rate exchange</h5>
            <FilterContainer>
              <div className='col'>
                <label>From</label>
                <AutoSuggestWrapper onChange={this.handleFromLocationChange} />
              </div>
              <div className='col'>
                <label>To</label>
                <AutoSuggestWrapper onChange={this.handleToLocationChange} />
              </div>
              <div className='col'>
                <StyledRfiCta onClick={this.handleFiltersApply}>Apply</StyledRfiCta>
              </div>
            </FilterContainer>
          </div>
          <RateWrapper ref={this.element}>
            {iffun(
              this.props.loading === true,
              <div style={{ width: '100%', minHeight: '350px', position: 'relative' }}>
                <Spinner />
              </div>,
              () => {
                if (liveFeed && liveFeed.length === 0) {
                  return <NoResultDiv>No results found</NoResultDiv>;
                } else {
                  return liveFeed.map((feed, index) => {
                    let startFormattedAddress = '';
                    let endFormattedAddress = '';
                    let truckMappingsType = '-';
                    let truckMappingsLength = '-';
                    let truckMappingsWeight = '-';
                    let displayVyomRate = '-';
                    try {
                      startFormattedAddress = feed.startAddress.formattedAddress;
                      endFormattedAddress = feed.endAddress.formattedAddress;
                      displayVyomRate = feed.displayVyomRate;
                    } catch (err) {
                      console.error(err);
                    }
                    if (truckMappings) {
                      try {
                        truckMappingsType = truckMappings.typeBindings[feed.vehicleTypeId];
                        truckMappingsLength = truckMappings.lengthBindings[feed.vehicleLengthId];
                        truckMappingsWeight = truckMappings.weightBindings[feed.vehicleWeightId];
                      } catch (err) {
                        console.error(err);
                      }
                      return (
                        <StyledLiveRateItem key={index}>
                          <p>
                            <span>
                              {startFormattedAddress} - {endFormattedAddress}
                            </span>
                            <em>{truckMappingsType}</em>
                            <CircleIcon color='#969696' />
                            <em>{truckMappingsLength}</em>
                            <CircleIcon color='#969696' />
                            <em>{truckMappingsWeight}</em>
                          </p>
                          <p>
                            <span>{displayVyomRate}</span>
                          </p>
                        </StyledLiveRateItem>
                      );
                    } else {
                      return <NoResultDiv>No results found</NoResultDiv>;
                    }
                  });
                }
              }
            )}
          </RateWrapper>
          <cite onClick={onCloseBtnClick} className='closeIcon'>
            <CrossIcon />
          </cite>
        </StyledDialog>
      </DialogWrapper>
    );
  }
}

LiveFeedPopup.propTypes = {
  onCloseBtnClick: PropTypes.func,
  liveFeed: PropTypes.array,
  getMoreLiveFeed: PropTypes.func,
  handleFiltersApply: PropTypes.func,
  truckMappings: PropTypes.object,
  loading: PropTypes.bool
};
