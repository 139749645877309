import React from 'react';
import styled from 'styled-components';
import iffun from '@bit/rivigo.ui.utils.iffun';
import PropTypes from 'prop-types';
import { RfiCta } from '../components/PrimaryCTA/PrimaryCTA';
import freightDesktopScreen from '../images/freight-desktop.png';
import freightMobileScreen from '../images/freight-mobile.png';
import googlePlayImage from '../images/googleplay-icon.png';
import { DialogWrapper, Dialog } from '../components-pure/Dialog';
import { TickCircleIcon } from '../custom-icons';
import { device } from '../constants/device';
import userServices from '../services/userServices';

const AppDwnldWrapper = styled.div`
  background: #f6f6fa;
  border-top: 1px solid #d8d8d8;
  padding: 60px 0;
  @media ${device.tablet} {
    &&& {
      padding: 40px 0;
      .dView {
        display: none;
      }
    }
  }
  @media ${device.midDevice2} {
    &&& {
      .mView {
        display: none;
      }
    }
  }
`;

const AppLayout = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .dwnldImage {
    width: 100%;
    max-width: 550px;
    height: auto;
    &.mobileScreen {
      max-width: 320px;
      margin-top: 20px;
    }
  }
  @media ${device.tablet} {
    flex-direction: column-reverse;
    text-align: center;
  }
`;

const AppDwnldFormWrapper = styled.div`
  min-width: 40%;
  h3 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 12px;
    color: #2a2a2a;
    small {
      font-size: 22px;
    }
  }
  .visitLink {
    font-size: 16px;
    margin: 30px 0;
    a {
      color: #e3383a;
      font-size: 22px;
      font-weight: 700;
    }
  }
  h4 {
    font-size: 22px;
  }
  .text {
    color: #969696;
    font-size: 16px;
    margin: 10px 0;
  }
  label {
    font-size: 14px;
    color: #2a2a2a;
    display: block;
    margin: 30px 0 10px;
    color: #969696;
  }
  .separator {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 180px;
    position: relative;
    &:before {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      height: 2px;
      background: #d8d8d8;
      z-index: 1;
      display: block;
    }
    small {
      color: #2a2a2a;
      width: 38px;
      height: 38px;
      line-height: 32px;
      text-align: center;
      border-radius: 50%;
      font-size: 12px;
      background: #d8d8d8;
      border: 4px solid #f6f6fa;
      z-index: 2;
      font-weight: 700;
    }
  }
  img {
    width: 150px;
    height: auto;
  }
  .appDwnldForm {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
    input {
      min-width: 63%;
      vertical-align: top;
      height: 42px;
      -webkit-appearance: none;
      font-size: 14px;
      padding: 15px;
      border-radius: 4px;
      border: 1px solid #d8d8d8;
      flex-grow: 1;
    }
  }
  @media ${device.tablet} {
    &&& {
      flex-grow: 1;
      h3 {
        font-size: 26px;
      }
      .visitLink {
        font-size: 16px;
        margin-bottom: 30px;
      }
      .appDwnldForm {
        flex-direction: column;
        margin-bottom: 30px;
        button {
          margin: 15px 0 0 0;
        }
      }
    }
  }
`;

const StyledRfiCta = styled(RfiCta)`
  &&& {
    height: 42px;
    margin-left: 15px;
  }
`;

const StyledDialog = styled(Dialog)`
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  max-width: 310px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    color: #2a2a2a;
  }
  svg {
    margin-bottom: 20px;
    color: #19b985;
    font-size: 60px;
  }
`;
class AppDownload extends React.Component {
  state = {
    showPopUp: false,
    phoneNumber: ''
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.userMobile) {
      this.setState({
        phoneNumber: nextProps.userMobile
      });
    }
  }

  sendSMS = () => {
    var mobileNum = this.state.phoneNumber;
    var obj = {
      url: `https://demand.vyom.com/api/util/senddownloadmsg.json`,
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        v: 24
      },
      body: JSON.stringify({ phoneNumber: mobileNum })
    };
    var url = new URL(obj.url);
    obj.headers = Object.assign(
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Accept-Encoding': 'application/gzip'
      },
      obj.headers
    );
    fetch(url.href, obj)
      .then(() => {
        userServices.sendAnalyticsEvents({
          category: 'Redirection',
          action: `Clicks-DownloadFreightApp-Sendlink`,
          label: `Success`
        });
        this.setState({ showPopUp: true });
        document.getElementById('phoneNumber').value = '';
        setTimeout(() => {
          this.setState({ showPopUp: false, phoneNumber: '' });
        }, 2000);
      })
      .catch((err) => {
        //TODO  Show ui notification
        userServices.sendAnalyticsEvents({
          category: 'Redirection',
          action: `Failure-DownloadFreightApp-Sendlink`,
          label: `Failure, ${err}`
        });
      });
  };

  onMobileNumberChange = (e) => {
    if (e.target.value.length !== 0 && isNaN(e.target.value) === true) {
      return;
    }
    userServices.sendAnalyticsEvents({
      category: 'Redirection',
      action: `DataEntry-DownloadFreightApp`,
      label: `Phone Number: ${e.target.value}`
    });
    this.setState({
      phoneNumber: e.target.value
    });
  };

  restrictSubmit = (e) => {
    e.preventDefault();
  };

  handleKeyDown = (e) => {
    if (String(e.keyCode) === '13') {
      if (this.state.phoneNumber.length === 10) {
        this.sendSMS();
      }
    }
  };

  handleLinkClick = () => {
    userServices.sendAnalyticsEvents({
      category: 'Redirection',
      action: `Clicks`,
      label: `freight.rivigo.com`
    });
  };

  handleGooglePlayImage = () => {
    userServices.sendAnalyticsEvents({
      category: 'Redirection',
      action: `Clicks-DownloadFreightApp-GetonGooglePlay`,
      label: ``
    });
  };

  render() {
    let { showPopUp } = this.state;
    return (
      <AppDwnldWrapper>
        <AppLayout>
          {iffun(
            showPopUp === true,
            <DialogWrapper>
              <StyledDialog>
                <TickCircleIcon />
                <p>Link Sent Successfully</p>
              </StyledDialog>
            </DialogWrapper>,
            null
          )}
          <figure>
            <img className='dwnldImage dView' src={freightDesktopScreen} />
            <img className='dwnldImage mobileScreen mView' src={freightMobileScreen} />
          </figure>
          <AppDwnldFormWrapper>
            <h3 className='dView'>
              <small>Get started with</small> RIVIGO Freight
            </h3>
            <h4 className='mView'>Get RIVIGO Freight App</h4>
            <p className='mView text'>Book a truck from your phone.</p>
            <p className='dView visitLink'>
              Visit us at{' '}
              <a
                onClick={this.handleLinkClick}
                href='https://freight.rivigo.com/'
                rel='noopener noreferrer'
                target='_blank'>
                freight.rivigo.com
              </a>
            </p>
            <content className='separator dView'>
              <small>OR</small>
            </content>
            <label className='dView'>Enter your phone number to receive the app link</label>
            <div className='appDwnldForm dView'>
              <input
                type='text'
                onChange={this.onMobileNumberChange.bind(this)}
                onKeyDown={this.handleKeyDown.bind(this)}
                value={this.state.phoneNumber}
                maxLength='10'
                placeholder='Enter Phone number'
                id='phoneNumber'
              />
              <StyledRfiCta onClick={this.sendSMS.bind(this)} disabled={this.state.phoneNumber.length < 10}>
                Send Link
              </StyledRfiCta>
            </div>
            <a
              onClick={this.handleGooglePlayImage}
              href='https://goo.gl/efCtzj'
              target='_blank'
              rel='noopener noreferrer'>
              <img src={googlePlayImage} />
            </a>
          </AppDwnldFormWrapper>
        </AppLayout>
      </AppDwnldWrapper>
    );
  }
}

export default AppDownload;

AppDownload.propTypes = {
  userMobile: PropTypes.string
};
